import { facebook, ga, adjust } from 'js/analytics/adapters'

export const trackCompleteRegistration = ({
  profile,
}: {
  profile: { id: number }
}) => {
  ga.track('complete_registration')
  facebook.track('CompleteRegistration')
  adjust.track('Complete Registration', profile.id)
}
