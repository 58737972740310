export const poundsToKilograms = (pounds) => {
  return Math.round(pounds * (1 / 2.2046226218) * 10) / 10
}

export const kilogramsToPounds = (kilograms) => {
  return Math.round(kilograms * 2.2046226218)
}

export const stonesAndPoundsToKilograms = (stones, pounds) => {
  return poundsToKilograms(stones * 14 + pounds)
}

export const feetAndInchesToCentimeters = (feet, inches) => {
  return Math.round(feet * 30.48 + inches * 2.54)
}

export const centimetersToFeetAndInches = (centimeters) => {
  return {
    feet: Math.floor((centimeters * 0.39370079) / 12),
    inches: Math.round((centimeters / 0.0254 / 100) % 12),
  }
}

export const poundsToStonesAndPounds = (pounds) => {
  return {
    stones: Math.floor(pounds / 14),
    pounds: Math.floor(pounds % 14),
  }
}

export const kilogramsToStonesAndPounds = (kilograms) => {
  return poundsToStonesAndPounds(kilogramsToPounds(kilograms))
}

export const stonesAndPoundsToPounds = (stones, pounds) => {
  return stones * 14 + pounds
}

export const kcalToKJ = (kcal) => {
  return Math.round(kcal * 4.184)
}
