import createAdapter from './createAdapter'
import {
  setSessionItem,
  getSessionItem,
  removeSessionItem,
  getItem,
} from '../../storeManager'
import { excludeVAT } from '../../utils'

export const adrecord = createAdapter(() => true, {
  trackPurchase(orderValue, currency) {
    if (!getItem('state')) {
      throw new Error('adrecordAdapter.ts error: no state saved')
    }

    const { user: userState, locale: localeState } = JSON.parse(
      getItem('state') as string,
    )
    const trackId = getSessionItem('adRecordTrackId')
    const userId = userState.profile.id || userState.limitedProfile.id
    const country = userState.profile.country || localeState.country

    if (trackId && userId) {
      let url = `https://track.adrecord.com/?trackID=${trackId}&programID=1122&orderID=${userId}&orderValue=${parseInt(
        excludeVAT(orderValue, country),
        10,
      )}&currency=${currency}`

      fetch(url, {
        method: 'POST',
      }).then(() => {
        removeSessionItem('adRecordTrackId')
      })
    }
  },
  saveId() {
    const trackId = new URLSearchParams(document.location.search).get('trackID')
    if (trackId) setSessionItem('adRecordTrackId', trackId)
  },
})
