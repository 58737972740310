/**
 * Specifies supported locales in the web and exports url slug translations
 * comma separated list of supported locales for easy access when needed:
 * en, da, nl, fr, de, it, no, ru, sv, es, 'pt-BR'
 */
declare var IS_PRODUCTION: boolean

export type LanguageCodes =
  | 'da'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it'
  | 'nl'
  | 'no'
  | 'pt'
  | 'ru'
  | 'sv'

export const language = {
  ENGLISH: 'en',
  DANISH: 'da',
  DUTCH: 'nl',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  NORWEGIAN: 'no',
  RUSSIAN: 'ru',
  SWEDISH: 'sv',
  SPANISH: 'es',
  PORTUGUESE: 'pt',
  ...(IS_PRODUCTION
    ? {}
    : {
        X_LONGEST: 'xl',
        X_PSEUDO: 'xp',
      }),
} as unknown as { [key: string]: LanguageCodes }

export const smartlingLocales = {
  en: 'en',
  da: 'da-DK',
  nl: 'nl-NL',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  no: 'no-NO',
  ru: 'ru-RU',
  sv: 'sv-SE',
  es: 'es-LA',
  'pt-BR': 'pt-BR',
}

export const apiLocales = {
  en: 'en-US',
  da: 'da-DK',
  nl: 'nl-NL',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  no: 'nb-NO',
  ru: 'ru-RU',
  sv: 'sv-SE',
  es: 'es-LA',
  pt: 'pt-BR',
}

export const languages = Object.values(language).sort() as LanguageCodes[]

export const translatedLanguageNames = {
  en: 'English',
  nl: 'Nederlands',
  sv: 'Svenska',
  fr: 'Français',
  de: 'Deutsch',
  no: 'Norsk',
  ru: 'Русский',
  da: 'Dansk',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  ...(IS_PRODUCTION
    ? {}
    : {
        xp: 'ƤŞḖḖŬŬḒǾǾ',
        xl: 'LONGEST TRANSLATION',
      }),
}

export const languageNames = {
  en: 'English',
  nl: 'Dutch',
  sv: 'Swedish',
  fr: 'French',
  de: 'German',
  no: 'Norwegian',
  ru: 'Russian',
  da: 'Danish',
  it: 'Italian',
  es: 'Spanish',
  pt: 'Portuguese',
}

export const currency = {
  USD: 'USD',
  AUD: 'AUD',
  CHF: 'CHF',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  NOK: 'NOK',
  NZD: 'NZD',
  SEK: 'SEK',
  CAD: 'CAD',
  BRL: 'BRL',
  MXN: 'MXN',
  INR: 'INR',
}

export type CurrencyCodes = keyof typeof currency

export const currencies = Object.values(currency)
