import { datoToken } from 'js/config'
import { isSSR } from 'js/isSSR'
import { LanguageCodes, languages } from './locale'

function datoEnvironment() {
  if (isSSR) return ''
  const environment = new URLSearchParams(window.location.search).get(
    'environment',
  )
  return environment ? `/environments/${environment}/` : ''
}

export function search(query: string, limit = 20, offset = 0) {
  return fetch(
    `https://site-api.datocms.com/search-results?q=${query}&limit=${limit}&offset=${offset}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'API-Token c32e5b8bde3bebefe4ede3bdc45364',
        Accept: 'application/json',
      },
    },
  )
    .then((res) => res.json())
    .then((json) => {
      if (!json.errors) return json
      throw json.errors
    })
}

export function runQuery(query: string, variables?: {}, isPreview?: boolean) {
  const TOKEN = datoToken

  return fetch(
    isPreview
      ? `https://graphql.datocms.com${datoEnvironment()}/preview`
      : `https://graphql.datocms.com${datoEnvironment()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  )
    .then((res) => res.json())
    .then((json) => {
      if (!json.errors) return json.data
      throw json.errors
    })
    .catch((error) => {
      console.error(
        'QUERY ERROR',
        error.length && error.map((error) => error.message).join('\n'),
        'on query',
        query,
        'Errors: ',
        error,
      )
      throw error
    })
}

export function mergeFallback(page: {}, fallback: {}) {
  const mergedPage = { ...page }

  Object.keys(page).forEach((key) => {
    if (page[key] === '') {
      mergedPage[key] = fallback[key]
    }

    if (Array.isArray(page[key]) && page[key].length === 0) {
      mergedPage[key] = fallback[key]
    }
  })

  return mergedPage
}

export function getDatoLocale(language: LanguageCodes) {
  if (language === 'pt') return 'pt_BR'
  if (!languages.includes(language)) return 'en'
  return language
}
