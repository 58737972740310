import React from 'react'
import { useEffect } from 'react'
import {
  fetchPricing,
  selectCoupon,
  clearCoupon,
  setCouponButDoNotOverride,
} from '../store/pricingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { runQuery } from 'js/dato'
import useLocale from '../hooks/useLocale'

const PriceUpdater = ({ children }) => {
  const { currency } = useLocale()
  const selectedCoupon = useSelector(selectCoupon)
  const dispatch = useDispatch()

  const updatePrices = () => {
    const coupon = selectedCoupon

    // Fetch base pricing
    dispatch(fetchPricing())

    if (coupon) {
      dispatch(fetchPricing(coupon)).catch(() => {
        // Check if this coupon is still the selected one
        if (coupon === selectedCoupon) {
          dispatch(clearCoupon())
        }
      })
    }
  }

  useEffect(() => {
    if (navigator.userAgent.match('DisableForTesting')) return
    if (selectedCoupon) return
    let abort = false
    const todayDateString = new Date().toISOString().substring(0, 10)
    runQuery(`query BlanketCampaign {
      blanketCampaign(
        orderBy: [_createdAt_DESC],
        filter: {startDate: {lte: "${todayDateString}"}, endDate: {gte: "${todayDateString}"}}) {
          startDate
          endDate
          couponCode
          name
        }
    }`).then(({ blanketCampaign }) => {
      if (abort) return
      if (!blanketCampaign) return

      const coupon = blanketCampaign.couponCode
      dispatch(fetchPricing(coupon)).then(() => {
        dispatch(setCouponButDoNotOverride(coupon))
      })
    })

    return () => {
      abort = true
    }
  }, [selectedCoupon, dispatch])

  useEffect(() => {
    updatePrices()
    // FIXME: dont ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  return React.Children.only(children)
}

export default PriceUpdater
