import createAdapter from './createAdapter'
import { ga4MeasurementId } from 'js/config'
import { pushToDataLayer } from 'js/pages/LandingPages/utils'
import { getItem, setItem } from 'js/storeManager'

const gaAdapter = createAdapter(() => !!window.gtag, {
  page({ url, pathname, title }) {
    gtag('config', ga4MeasurementId as string, {
      page_location: url,
      page_title: title,
      page_path: pathname,
    })
    const firstPage = getItem('FirstPage')
    if (!firstPage) {
      setItem('FirstPage', url)
      gtag('set', 'user_properties', {
        FirstPage: url,
      })
    }
  },
  track(eventAction, eventProperties = {}) {
    if (
      !Object.prototype.hasOwnProperty.call(eventProperties, 'eventCategory')
    ) {
      eventProperties['eventCategory'] = 'All'
    }
    eventProperties['eventAction'] = eventAction
    eventProperties['hitType'] = 'event'

    // This pushes an array like this ['event', eventAction, { ...eventProperties }] to the dataLayer
    // this is to be picked up by the our old google analytics account directly
    gtag('event', eventAction, eventProperties)

    // This pushes an object like this { event: eventAction, ...eventProperties } to the dataLayer
    // this is to be picked up by our GTM account and sent to our new google analytics account
    // by configuration in GTM
    pushToDataLayer({ event: eventAction, ...eventProperties })
  },
  set(dimension, value) {
    gtag('set', dimension, value)
  },
  user(key, value) {
    gtag('set', 'user_properties', {
      [key]: value,
    })
  },
  virtualPageView(page) {
    gtag('config', ga4MeasurementId as string, {
      page_path: page,
    })
  },
})

export { gaAdapter as ga }
