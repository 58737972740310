import { isSSR } from 'js/isSSR'

const storageIsEnabled = () => !isSSR && navigator.cookieEnabled

export const setItem = (item, value) => {
  if (storageIsEnabled()) {
    return localStorage.setItem(item, value)
  }
}

export const getItem = (item) => {
  if (storageIsEnabled()) {
    return localStorage.getItem(item)
  }

  return null
}

export const removeItem = (item) => {
  if (storageIsEnabled()) {
    return localStorage.removeItem(item)
  }
}

export const setSessionItem = (item, value) => {
  if (storageIsEnabled()) {
    return sessionStorage.setItem(item, value)
  }
}

export const getSessionItem = (item) => {
  if (storageIsEnabled()) {
    return sessionStorage.getItem(item)
  }

  return null
}

export const removeSessionItem = (item) => {
  if (storageIsEnabled()) {
    return sessionStorage.removeItem(item)
  }
}
