export const languageCookieKey = 'storedLanguage'

export const getAbTestsFromCookie = (cookie) => {
  const expCookieMatch = cookie.match(/exp=([^;$]+)/)
  if (!expCookieMatch) {
    return {}
  }

  return expCookieMatch[1].split(':').reduce((tests, test) => {
    const [experimentId, variantIndex] = test.split('.')
    return {
      ...tests,
      [experimentId]: Number(variantIndex),
    }
  }, {})
}
