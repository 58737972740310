const key = 'state'

export const saveState = (state) => {
  try {
    const serialized = JSON.stringify(state)
    localStorage.setItem(key, serialized)
  } catch (err) {
    // Nothing
  }
}

export const loadState = () => {
  try {
    const serialized = localStorage.getItem(key)

    if (serialized === null) {
      return undefined
    }

    return JSON.parse(serialized)
  } catch (err) {
    return undefined
  }
}

export const migrate = (migrations) => (oldState) => {
  let newState = oldState
  let version = oldState.__version || 0
  const targetVersion = getLatestVersion(migrations) || 0

  try {
    while (version < targetVersion) {
      version++
      newState = migrations[version](newState)
    }

    return {
      ...newState,
      __version: version,
    }
  } catch (err) {
    return { __version: targetVersion }
  }
}

function getLatestVersion(migrations) {
  if (typeof migrations !== 'object') return undefined

  return Object.keys(migrations)
    .map((key) => parseInt(key))
    .sort()
    .pop()
}
