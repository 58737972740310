import cn from 'classnames'
import React from 'react'

const primaryClasses = `
  hover:bg-LSBrandHover focus:bg-LSBrandHover active:bg-LSBrandActive
  text-white 
`
const secondaryClasses = `
  text-Type hover:text-TypeSub focus:text-TypeSub active:text-black
  border-Type hover:border-TypeSub focus:border-TypeSub active:border-black
  border-Type border-1
`
type LinkProps = {
  size?: 'small' | 'large' | 'auto'
  children: React.ReactNode
  className?: string
  variant: 'primary' | 'secondary'
  link: true
  href?: string | null
  onClick?: () => void
  disabled?: boolean
  target?: string
  upperCase?: boolean
}

type ButtonProps = {
  size?: 'small' | 'large' | 'auto'
  children: React.ReactNode
  className?: string
  variant: 'primary' | 'secondary'
  link: false | undefined
  href?: string | null
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  upperCase?: boolean
}

type Props = ButtonProps | LinkProps

const LSButton = ({
  size = 'auto',
  link,
  className,
  children,
  variant,
  href,
  disabled,
  upperCase,
  onClick,
  ...props
}: Props) => {
  const mergedClassName = cn(
    `mx-auto inline-block rounded-8 text-center text-14
     font-demibold px-32 transition cursor-pointer shadow-subtle`,
    className,
    {
      'bg-LSBrand': variant === 'primary' && !disabled,
      'bg-TypeDisabled': variant === 'primary' && disabled,
      'py-[9.5px] lg:py-16': size === 'auto',
      'py-[9.5px]': size === 'small',
      'py-16': size === 'large',
      uppercase: upperCase,
      [primaryClasses]: variant === 'primary' && !disabled,
      [secondaryClasses]: variant === 'secondary',
    },
  )

  const content = upperCase ? (
    <div style={{ transform: 'translateY(1.5px)' }}>{children}</div>
  ) : (
    children
  )
  if (link === true && href !== null) {
    return (
      <a className={mergedClassName} href={href} onClick={onClick} {...props}>
        {content}
      </a>
    )
  }

  return (
    <button
      className={mergedClassName}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {content}
    </button>
  )
}

export default LSButton
