import { createSlice, createSelector } from '@reduxjs/toolkit'
import {
  loginWithEmail as loginWithEmailAuth,
  loginWithFacebook as loginWithFacebookAuth,
  loginWithApple as loginWithAppleAuth,
  loginWithGoogle as loginWithGoogleAuth,
  deleteAccount as deleteAccountAuth,
  cancelSubscription as cancelSubscriptionAuth,
  createAccount as createAccountAuth,
  verifyAccountEmail as verifyAccountEmailAuth,
  getUserProfile,
  updateUserProfile as updateUserProfileAuth,
  getAccountInfoByUserId,
} from '../auth'
import { loadState, migrate } from './localStorage'
import messages from './messages'
import {
  trackLogin,
  trackLogOut,
  trackCompleteRegistration,
  trackErrorMessage,
} from '../analytics'
import resetTracking from '../analytics/tracking/reset'
import differenceInYears from 'date-fns/differenceInYears'
import {
  kilogramsToPounds,
  kilogramsToStonesAndPounds,
} from '../metricsConverter'
import { jwtDecode } from 'jwt-decode'
import { captureException } from 'js/utils/captureException'
import { debug } from 'js/utils/debug'

const defaultState = {
  errors: {},
  isLoggedIn: false,
  isLoggingIn: false,
  isDeleted: false,
  isDeleting: false,
  isCancelling: false,
  isCreating: false,
  isVerifying: false,
  isUpdating: false,
  isLoadingLimitedProfile: false,
  userToken: undefined,
  userTokenExpiration: undefined,
  profile: {},
}

export const migrations = {
  3: (state) => {
    if (state.isCancelled) {
      delete state.isCancelled
    }
    return state
  },
  4: (state) => {
    if (!state.userTokenExpiration && state.userToken) {
      const { exp } = jwtDecode(state.userToken) as { exp: number }
      state.userTokenExpiration = exp
    } else {
      state.userTokenExpiration = undefined
    }
    return state
  },
}

const previousState = loadState()

const initialState = {
  ...Object.assign(
    {},
    defaultState,
    previousState && previousState.user
      ? migrate(migrations)(previousState.user)
      : {},
  ),
  errors: {},
  isDeleted: false,
  isLoggingIn: false,
  isDeleting: false,
  isCancelling: false,
  isCreating: false,
  isVerifying: false,
  __version: 4,
}

export const mapApiProfileToObject = (apiProfile) => {
  return {
    autoRenewing: !!apiProfile.autorenewing,
    birthDate: apiProfile.birthdate,
    hasCancelled: !!apiProfile.cancelled,
    country: apiProfile.country,
    email: apiProfile.email,
    expiresTime: apiProfile.premium_purchase_time
      ? apiProfile.premium_expires_time
      : apiProfile.next_charge_date,
    firstName: apiProfile.firstname,
    gender: apiProfile.gender,
    lastName: apiProfile.lastname,
    paymentMeta:
      apiProfile.payment_meta || apiProfile.card_info
        ? apiProfile.card_info
          ? {
              cardSummary: apiProfile.card_info.card_summary,
              cardType: apiProfile.card_info.card_type
                ? apiProfile.card_info.card_type
                : false,
              expires: apiProfile.card_info.expiry_date,
              nameOnCard: apiProfile.card_info.name,
            }
          : {
              cardSummary: apiProfile.payment_meta.card_summary,
              cardType: false,
              expires: apiProfile.payment_meta.expires,
              nameOnCard: false,
            }
        : {},
    paymentMethod: apiProfile.payment_meta
      ? apiProfile.payment_meta.payment_method
      : apiProfile.payment_method,
    isPremium: apiProfile.premium,
    image: apiProfile.profile_image,
    facebookImage: apiProfile.facebook_profile_image,
    purchaseTime: apiProfile.premium_purchase_time,
    purchaseType: apiProfile.premium_purchase_type,
    isRefunded: !!apiProfile.refunded,
    store: apiProfile.store,
    id: apiProfile.userid,
    currencyLocal:
      apiProfile.next_charge_price && apiProfile.next_charge_price.currency,
    nextPaymentAmountLocal:
      apiProfile.next_charge_price && apiProfile.next_charge_price.value,
    isNewStyleSubscription: apiProfile.new_style_subscription,
    status: apiProfile.status,
    targetWeight: apiProfile.targetweight,
    goal: apiProfile.loseweight,
    measurementType: apiProfile.measurement_type,
    paymentProvider: apiProfile.payment_provider,
  }
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserToken(state, { payload: { accessToken, expiresAt } }) {
      state.userToken = accessToken
      state.userTokenExpiration = expiresAt
    },
    setUserProfile(state, { payload: profile }) {
      state.profile = profile
    },
    mergeUserProfile(state, { payload: profile }) {
      state.profile = { ...state.profile, ...profile }
    },
    logOut(state) {
      trackLogOut()
      resetTracking()

      return {
        ...state,
        isLoggedIn: false,
        isDeleted: false,
        userToken: undefined,
        userTokenExpiration: undefined,
        profile: {},
      }
    },
    reset(state) {
      return {
        __version: state.__version,
        ...defaultState,
      }
    },
    upgradeToPremium(state) {
      state.profile.isPremium = true
    },
    downgradeToFree(state) {
      state.profile.isPremium = false
    },
    logInRequest(state) {
      state.isLoggedIn = false
      state.isLoggingIn = true
      state.isDeleted = false
      state.isDeleting = false
      state.isCancelling = false
    },
    logInSuccess(state) {
      state.isLoggedIn = true
      state.isLoggingIn = false
      state.isDeleted = false
      state.isDeleting = false
      state.isCancelling = false
    },
    logInFailure(state) {
      state.isLoggedIn = false
      state.isLoggingIn = false
      state.isDeleted = false
      state.isDeleting = false
      state.isCancelling = false
      state.userToken = ''
      state.profile = {}
    },
    deleteRequest(state) {
      state.isDeleted = false
      state.isDeleting = true
    },
    deleteSuccess(state) {
      state.isDeleted = true
      state.isDeleting = false
    },
    deleteFailure(state) {
      state.isDeleting = false
    },
    cancelRequest(state) {
      state.isCancelling = true
      state.errors.cancel = undefined
    },
    cancelSuccess(state) {
      state.isCancelling = false
      state.errors.cancel = undefined
    },
    cancelFailure(state, { payload: errorMessage }) {
      state.isCancelling = false
      state.errors.cancel = errorMessage
    },
    createRequest(state) {
      state.isCreating = true
      state.errors.create = undefined
    },
    createSuccess(state) {
      state.isCreating = false
      state.errors.create = undefined
    },
    createFailure(state, { payload: errorMessage }) {
      state.isCreating = false
      state.errors.create = errorMessage
    },
    verifyRequest(state) {
      state.isVerifying = true
      state.errors.verify = undefined
    },
    verifySuccess(state) {
      state.isVerifying = false
      state.errors.verify = undefined
    },
    verifyFailure(state, { payload: errorMessage }) {
      state.isVerifying = false
      state.errors.verify = errorMessage
    },
    updateRequest(state) {
      state.isUpdating = true
      state.errors.update = undefined
    },
    updateSuccess(state) {
      state.isUpdating = false
      state.errors.update = undefined
    },
    updateFailure(state, { payload: errorMessage }) {
      state.isUpdating = false
      state.errors.update = errorMessage
    },
    limitedProfileRequest(state) {
      state.isLoadingLimitedProfile = true
    },
    limitedProfileSuccess(state) {
      state.isLoadingLimitedProfile = false
    },
    limitedProfileFailure(state, { payload: errorMessage }) {
      state.isLoadingLimitedProfile = false
      state.errors.limitedProfile = errorMessage
    },
    setLimitedProfile(state, { payload: profile }) {
      state.limitedProfile = profile
    },
    clearLimitedProfile(state) {
      delete state.limitedProfile
    },
  },
  extraReducers: (builder) => {
    // add a reducer that runs on all actions and resets login when the session
    // access token (logged in session) has expired
    builder.addMatcher(
      () => true,
      (state) => {
        if (state.userTokenExpiration <= Math.floor(Date.now() / 1000)) {
          state.userToken = undefined
          state.userTokenExpiration = undefined
          state.isLoggedIn = false
          state.isDeleted = false
          state.profile = {}
        }
      },
    )
  },
})

export const {
  cancelFailure,
  cancelRequest,
  cancelSuccess,
  updateFailure,
  updateRequest,
  updateSuccess,
  deleteFailure,
  deleteRequest,
  deleteSuccess,
  downgradeToFree,
  logInFailure,
  logInRequest,
  logInSuccess,
  createRequest,
  createSuccess,
  createFailure,
  verifyRequest,
  verifyFailure,
  verifySuccess,
  logOut,
  setUserProfile,
  mergeUserProfile,
  setUserToken,
  upgradeToPremium,
  reset,
  setLimitedProfile,
  clearLimitedProfile,
  limitedProfileRequest,
  limitedProfileFailure,
  limitedProfileSuccess,
} = slice.actions

export const fetchUserProfile = () => (dispatch) =>
  new Promise<{ id: number }>((resolve, reject) => {
    getUserProfile()
      .then((profile) => {
        const user = mapApiProfileToObject(profile)
        dispatch(setUserProfile(user))
        dispatch(clearLimitedProfile())
        resolve(user)
      })
      .catch((error) => {
        dispatch(logOut())
        reject(error)
      })
  })

export const loginWithEmail =
  ({ email, password }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(logInRequest())

      loginWithEmailAuth(email, password).then(
        ({ access_token: accessToken, expires_at: expiresAt }) => {
          dispatch(setUserToken({ accessToken, expiresAt }))
          dispatch(fetchUserProfile())
            .then((user) => {
              trackLogin({ method: 'Email' })
              dispatch(logInSuccess())
              resolve(user)
            })
            .catch(() => {
              dispatch(logInFailure())
              reject(messages.wentWrong)
            })
        },
        (error) => {
          dispatch(logInFailure())

          if (error.status === 401) {
            trackErrorMessage({
              type: 'Invalid email login credentials',
              category: 'Email login',
            })

            // TODO: use new copy
            reject(messages.incorrect)
            return
          }

          // the return from reject is used in such a way that we need to
          // call Sentry like this to see the error
          captureException(error)

          reject(messages.wentWrong)
        },
      )
    })

export const loginWithFacebook =
  (token?: string, shouldDebug?: boolean) => (dispatch) =>
    new Promise<void>((resolve, reject) => {
      dispatch(logInRequest())

      loginWithFacebookAuth(token, shouldDebug)
        .then(({ access_token: accessToken, expires_at: expiresAt }) => {
          dispatch(setUserToken({ accessToken, expiresAt }))
          dispatch(fetchUserProfile())
            .then(() => {
              dispatch(logInSuccess())
              trackLogin({ method: 'Facebook' })
              resolve()
            })
            .catch(() => {
              dispatch(logInFailure())
              reject(messages.wentWrong)
            })
        })
        .catch((error) => {
          if (shouldDebug) {
            debug(`loginWithFacebook error shouldDebug: ${error.message}`)
          }
          dispatch(logInFailure())
          if (error && error.status === 401) {
            trackErrorMessage({
              type: 'Account not connected',
              category: 'Facebook login',
            })
            reject(messages.facebookNotConnected)
          } else {
            trackErrorMessage({
              type: 'Something went wrong',
              category: 'Facebook login',
            })
            reject(messages.facebookWentWrong)
          }
        })
    })

export const loginWithApple = () => (dispatch) =>
  new Promise<void>((resolve, reject) => {
    dispatch(logInRequest())

    loginWithAppleAuth()
      .then(({ access_token: accessToken, expires_at: expiresAt }) => {
        dispatch(setUserToken({ accessToken, expiresAt }))
        dispatch(fetchUserProfile())
          .then(() => {
            dispatch(logInSuccess())
            trackLogin({ method: 'Apple' })
            resolve()
          })
          .catch(() => {
            dispatch(logInFailure())
            reject(messages.wentWrong)
          })
      })
      .catch((error) => {
        dispatch(logInFailure())

        if (error && error.status === 401) {
          trackErrorMessage({
            type: 'Account not connected',
            category: 'Sign in with Apple',
          })
          return reject(messages.appleNotConnected)
        }

        trackErrorMessage({
          type: 'Something went wrong',
          category: 'Sign in with Apple',
        })

        return reject(messages.appleWentWrong)
      })
  })

export const loginWithGoogle = () => (dispatch) =>
  new Promise<void>((resolve, reject) => {
    dispatch(logInRequest())
    loginWithGoogleAuth()
      .then(({ access_token: accessToken, expires_at: expiresAt }) =>
        dispatch(setUserToken({ accessToken, expiresAt })),
      )
      .then(() => dispatch(fetchUserProfile()))
      .then(() => {
        dispatch(logInSuccess())
        trackLogin({ method: 'Google' })
        resolve()
      })
      .catch((error) => {
        dispatch(logInFailure())
        if (error && error.status === 401) {
          return reject(messages.googleNotConnected)
        }

        if (error && error.error === 'popup_blocked_by_browser') {
          return reject(messages.allowPopups)
        }

        return reject(messages.googleWentWrong)
      })
  })

export const deleteAccount = () => (dispatch, getState) =>
  new Promise<void>((resolve, reject) => {
    dispatch(deleteRequest())

    deleteAccountAuth(getState().user.profile.id).then(
      () => {
        dispatch(logOut())
        dispatch(deleteSuccess())
        resolve()
      },
      () => {
        dispatch(deleteFailure())
        reject(messages.deleteWentWrong)
      },
    )
  })

export const cancelSubscription = () => (dispatch) =>
  new Promise<void>((resolve, reject) => {
    dispatch(cancelRequest())

    cancelSubscriptionAuth().then(
      () => {
        dispatch(fetchUserProfile()).then(
          () => {
            dispatch(cancelSuccess())
            resolve()
          },
          () => {
            dispatch(cancelFailure(messages.cancelError))
            reject()
          },
        )
      },
      () => {
        dispatch(cancelFailure(messages.cancelError))
        reject()
      },
    )
  })

export const createAccount =
  ({ profile }) =>
  (dispatch) =>
    new Promise<void>((resolve, reject) => {
      dispatch(createRequest())

      return (
        createAccountAuth(profile)
          // eslint-disable-next-line no-unused-vars
          .then(({ userid: userId }) => {
            dispatch(createSuccess())
            const { email, password, oauth_service, oauth_token } = profile

            if (oauth_service === 'facebook') {
              dispatch(loginWithFacebook(oauth_token))
            } else {
              dispatch(loginWithEmail({ email, password }))
            }

            return userId
          })
          .then((userId) => {
            trackCompleteRegistration({
              profile: { ...profile, id: userId },
              // type: 'Free',
              // method: getSessionItem('registration-method'),
              // language,
              // country: profile.country,
            })
            if (typeof window['hj'] === 'function') {
              window['hj']('trigger', 'select_premium_subscription')
            }
            resolve()
          })
          .catch((error) => {
            dispatch(createFailure(error))
            reject()
            if (error?.errorType !== 'existing_user') {
              captureException(error)
            }
          })
      )
    })

type VerifyEmailResponse = {
  isPremium: boolean
  id: number | null
  email: string | null
}
export const verifyAccountEmail = (code) => (dispatch) =>
  new Promise<VerifyEmailResponse>((resolve, reject) => {
    dispatch(verifyRequest())
    dispatch(logOut())

    verifyAccountEmailAuth(code)
      .then(({ response: { is_premium: isPremium, verified, id, email } }) => {
        if (verified) {
          dispatch(verifySuccess())
          resolve({ isPremium, id, email })
        } else {
          throw new Error('No token or verified')
        }
      })
      .catch(() => {
        dispatch(verifyFailure(messages.verifyError))
        reject()
      })
  })

export const updateUserProfile =
  ({ birthDate: birthdate, firstName: firstname, gender, country }) =>
  (dispatch) =>
    new Promise<void>((resolve, reject) => {
      dispatch(updateRequest())

      updateUserProfileAuth({
        birthdate,
        firstname,
        gender,
        country,
      }).then(
        (profile) => {
          dispatch(mergeUserProfile(mapApiProfileToObject(profile)))
          dispatch(updateSuccess())
          resolve()
        },
        () => {
          dispatch(updateFailure(messages.updateError))
          reject()
        },
      )
    })

export const fetchLimitedAccountInfo = (userId) => (dispatch) => {
  dispatch(limitedProfileRequest())

  return new Promise<void>((resolve, reject) => {
    getAccountInfoByUserId(userId)
      .then((res) => {
        dispatch(
          setLimitedProfile({
            email: res.email,
            isPremium: res.is_gold,
            id: userId,
          }),
        )
        dispatch(limitedProfileSuccess())
        dispatch(logOut())
        resolve()
      })
      .catch(() => {
        dispatch(limitedProfileFailure(messages.wentWrong))
        reject()
      })
  })
}

const selectUser = ({ user }) => user || {}

export const selectUserErrors = createSelector(
  [selectUser],
  ({ errors }) => errors,
)

export const selectIsLoggedIn = createSelector(
  [selectUser],
  ({ isLoggedIn }) => isLoggedIn,
)

const selectUserProfile = createSelector(
  [selectUser],
  ({ profile }) => profile || {},
)

export const selectIsPremium = createSelector(
  [selectUserProfile],
  ({ isPremium }) => !!isPremium,
)

export const selectIsOnFreeTrial = createSelector(
  [selectUserProfile],
  ({ status }) => status === 'trial',
)

export const selectIsCancelling = createSelector(
  [selectUser],
  ({ isCancelling }) => !!isCancelling,
)

export const selectHasCancelled = createSelector(
  [selectUserProfile],
  ({ hasCancelled }) => !!hasCancelled,
)

export const selectFirstName = createSelector(
  [selectUserProfile],
  ({ firstName }) => firstName,
)

export const selectGender = createSelector(
  [selectUserProfile],
  ({ gender }) => gender,
)

export const selectBirthDate = createSelector(
  [selectUserProfile],
  ({ birthDate }) => (birthDate ? new Date(birthDate) : undefined),
)

export const selectAge = createSelector([selectBirthDate], (date) => {
  if (!date) return undefined
  return differenceInYears(Date.now(), date)
})

export const selectEmail = createSelector(
  [selectUserProfile],
  ({ email }) => email,
)

export const selectProfileImage = createSelector(
  [selectUserProfile],
  ({ image }) => image,
)

export const selectFacebookProfileImage = createSelector(
  [selectUserProfile],
  ({ facebookImage }) => facebookImage,
)

export const selectCountry = createSelector(
  [selectUserProfile],
  ({ country }) => country,
)

export const selectSubscriptionRenewalDate = createSelector(
  [selectUserProfile],
  ({ autoRenewing, expiresTime }) => {
    if (!autoRenewing) return undefined
    return new Date(expiresTime)
  },
)

export const selectSubscriptionExpiryTime = createSelector(
  [selectUserProfile],
  ({ expiresTime }) => (expiresTime ? new Date(expiresTime) : undefined),
)

export const selectSubscriptionStore = createSelector(
  [selectUserProfile],
  ({ store }) => store,
)

export const selectGoal = createSelector(
  [selectUserProfile],
  ({ goal }) => goal,
)

export const selectTargetWeight = createSelector(
  [selectUserProfile],
  ({ targetWeight, measurementType }) => {
    let convertedTargetWeight = targetWeight
    let unit = 'kg'

    switch (measurementType) {
      case 'us':
        convertedTargetWeight = kilogramsToPounds(targetWeight)
        unit = 'lbs'
        break
      case 'uk':
        convertedTargetWeight = kilogramsToStonesAndPounds(targetWeight)
        unit = 'st'
        break
    }
    return { targetWeight: convertedTargetWeight, unit }
  },
)

export const selectSubscriptionLength = createSelector(
  [selectUserProfile],
  ({ purchaseType }) => purchaseType,
)

export const selectPaymentMetaData = createSelector(
  [selectUserProfile],
  ({ paymentMeta, paymentMethod }) => {
    return Object.assign({}, paymentMeta, { paymentMethod: paymentMethod })
  },
)

export const selectNextPaymentAmount = createSelector(
  [selectUserProfile],
  ({ nextPaymentAmountLocal }) => nextPaymentAmountLocal,
)

export const selectNextPaymentCurrency = createSelector(
  [selectUserProfile],
  ({ currencyLocal }) => currencyLocal,
)

export const selectLimitedProfile = createSelector(
  [selectUser],
  ({ limitedProfile }) => limitedProfile,
)

export const selectId = createSelector([selectUserProfile], ({ id }) => id)

export const selectIsAuthenticated = createSelector(
  [selectUser],
  ({ userToken }) => Boolean(userToken),
)

export const selectPurchaseTime = createSelector(
  [selectUserProfile],
  ({ purchaseTime }) => purchaseTime,
)

export const selectPaymentProvider = createSelector(
  [selectUserProfile],
  ({ paymentProvider }) => paymentProvider,
)

export { initialState }
export const { reducer } = slice
