// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _googleTypes from 'signin-with-google-types'

import api from 'js/api'
import { googleLoginId } from 'js/config'
import { IdConfiguration } from 'signin-with-google-types'

function getLoginToken() {
  return new Promise((resolve) => {
    const googleSignInButtonId = 'googleSignInButton'
    function renderSignInButton() {
      const googleSignInButton = document.getElementById(googleSignInButtonId)
      window.google.accounts.id.renderButton(
        googleSignInButton as HTMLElement,
        {
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
        },
      )
    }

    window.google.accounts.id.initialize({
      client_id: googleLoginId as string,
      use_fedcm_for_prompt: true,
      callback: ({ credential }) => {
        resolve(credential)
      },
    } as IdConfiguration)
    renderSignInButton()
    document
      ?.getElementById(googleSignInButtonId)
      ?.querySelector('div[role=button]')
      // @ts-ignore
      ?.click()
  })
}

export const loginWithGoogle = () => {
  return new Promise<{
    access_token: string
    expires_at: number
    refresh_token: string
  }>((resolve, reject) => {
    getLoginToken()
      .then((token) => {
        return api('POST', 'gatekeeper/v1/login/google/token', {
          token,
        })
      })
      .then(({ access_token, expires_at, refresh_token }) => {
        resolve({
          access_token,
          expires_at,
          refresh_token,
        })
      })
      .catch((error) => {
        return reject(error)
      })
  })
}
