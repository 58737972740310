import { isSSR } from './isSSR'

const { platform = '', userAgent = '' } = isSSR
  ? { platform: 'SSR', userAgent: 'SSR' }
  : navigator

// Are we running on iOS?
export const iOS = /iPad|iPhone|iPod/.test(platform)

// Are we running on android?
export const android = userAgent.toLowerCase().indexOf('android') !== -1

export const samsung =
  userAgent.toLowerCase().indexOf('samsung') !== -1 ||
  userAgent.toLowerCase().indexOf('sm-') > -1

// Deeplinks to various parts of the native apps
export const deeplinks = {
  recipes: (() => {
    if (iOS) return 'shapeupclub://deeplink?action_id=recipe_browse'
    if (android) return 'shapeupclub://?id=21'
  })(),
  premium: (() => {
    if (iOS) return 'shapeupclub://deeplink?action_id=gold'
    if (android) return 'shapeupclub://?action_id=3'
  })(),
}

// URL to the app store of the current device's platform
export const appStoreURL = (() => {
  if (iOS) return '//appsto.re/se/dLDgr.i'
  if (android)
    return '//play.google.com/store/apps/details?id=com.sillens.shapeupclub'
})()

export const appButtonUrls = {
  apple: 'https://app.adjust.com/mzt4tv?engagement_type=fallback_click',
  google: 'https://app.adjust.com/ufzn8u?engagement_type=fallback_click',
  samsung:
    'https://apps.samsung.com/appquery/appDetail.as?appId=com.lifesum.madeforsamsung',
}

export const device = iOS
  ? 'iOS'
  : android
  ? 'android'
  : samsung
  ? 'samsung'
  : null

export const appButtonUrl = (() => {
  if (android)
    return 'https://app.adjust.com/ufzn8u?engagement_type=fallback_click'
  if (samsung)
    return 'https://apps.samsung.com/appquery/appDetail.as?appId=com.lifesum.madeforsamsung'
  return 'https://app.adjust.com/mzt4tv?engagement_type=fallback_click'
})()
