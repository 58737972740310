import { apiHost } from './config'

const defaultHeaders = {
  'Content-Type': 'application/json',
}

let currentUserToken: null | string = null
let currentUserTokenExpiration: null | number = null

type BasicApiProps = {
  method: string
  data?: {}
  options?: {}
  headers?: {}
}

export const basicApi = (
  endpoint: string,
  { method, data, options, headers = {} }: BasicApiProps,
) => {
  if (currentUserToken && currentUserTokenExpiration) {
    if (Date.now() >= currentUserTokenExpiration * 1000) {
      currentUserToken = null
      currentUserTokenExpiration = null
    } else {
      headers['Authorization'] = `Bearer ${currentUserToken}`
    }
  }

  return fetch(`${apiHost}/${endpoint}`, {
    method: method.toUpperCase(),
    headers: { ...defaultHeaders, ...headers },
    body: JSON.stringify(data),
    ...options,
  })
}

const api = (
  method: string,
  endpoint: string,
  data?: {},
  options?: {},
  headers?: {},
) => {
  return basicApi(endpoint, {
    method,
    data,
    options,
    headers,
  }).then((response) => {
    const { ok, status, url } = response

    if (ok) {
      return response.json().catch(() => {
        throw `Malformed JSON from ${endpoint}`
      })
    }

    if (url.match(/\/gatekeeper\//)) {
      return Promise.reject({
        status,
      })
    }

    if (status === 400)
      return response.json().then((error) => Promise.reject(error))

    return response
      .json()
      .catch(() => {
        throw `Malformed JSON from ${endpoint}`
      })
      .then((error) => {
        if (error?.meta) {
          throw error.meta
        }
        if (error) {
          throw error
        }

        throw new Error(
          `ApiError without response: url ${url}, status ${status}, headers ${JSON.stringify(
            { ...defaultHeaders, ...headers },
          )}`,
        )
      })
  })
}

type SetTokenProps = {
  accessToken: string | null
  expiresAt: number | null
}

export function setToken(
  { accessToken = null, expiresAt = null }: SetTokenProps = {
    accessToken: null,
    expiresAt: null,
  },
) {
  currentUserToken = accessToken
  currentUserTokenExpiration = expiresAt
}

export default api
