if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}
import React from 'react'
import { hydrateRoot, createRoot } from 'react-dom/client'
import { HeadProvider } from 'react-head'
import '../index.scss'
import App from './AppComponent'
import { Provider } from 'react-redux'
import { getStoreConfiguration } from './store/store'
import { configureStore } from '@reduxjs/toolkit'
import { BrowserRouter } from 'react-router-dom'
import routes, { clientRoutes } from './routes'
import { loadableReady } from '@loadable/component'
import 'what-input'
import { getAbTestsFromCookie } from 'lifesum-ssr/server/shared'

const store = configureStore(getStoreConfiguration(window.PRELOADED_STATE))
const rootElement = document.getElementById('mount') as HTMLDivElement
const abTests = getAbTestsFromCookie(document.cookie || '')

export type AppDispatch = typeof store.dispatch

Promise.all([
  import(/* webpackChunkName: "Throttle" */ 'throttle-debounce'),
  import(/* webpackChunkName: "LocalStorage" */ './store/localStorage'),
]).then(([{ throttle }, storage]) =>
  store.subscribe(
    throttle(1000, () => {
      storage.saveState(store.getState())
    }),
  ),
)

async function loadPolyfills() {
  const polyfills: Array<Promise<any>> = []
  if (typeof window.IntersectionObserver === 'undefined') {
    polyfills.push(import('intersection-observer'))
  }

  if (
    !('fetch' in window) ||
    !('startsWith' in String.prototype) ||
    !('endsWith' in String.prototype) ||
    !('includes' in Array.prototype) ||
    !('flatMap' in Array.prototype) ||
    !('assign' in Object) ||
    !('keys' in Object) ||
    !('values' in Object)
  ) {
    polyfills.push(import('whatwg-fetch'))
    polyfills.push(import('./polyfills'))
  }

  await Promise.all(polyfills)
}

loadPolyfills().then(() => {
  if (rootElement.hasChildNodes()) {
    if (window?.performance?.mark) {
      window.performance.mark('loadableReady-start')
    }
    loadableReady(() => {
      if (window?.performance?.mark) {
        window.performance.mark('loadableReady-end')
        window.performance.measure(
          'loadableReady',
          'loadableReady-start',
          'loadableReady-end',
        )
      }

      hydrateRoot(
        rootElement,
        <Provider store={store}>
          <HeadProvider>
            <BrowserRouter>
              <App
                routes={[...routes, ...clientRoutes]}
                initialData={window.DATA}
                initialPathname={window.location.pathname}
                defaultMessages={window.DEFAULT_MESSAGES}
                abTests={abTests}
                cloudfrontViewerCountry={window.CLOUDFRONT_VIEWER_COUNTRY}
              />
            </BrowserRouter>
          </HeadProvider>
        </Provider>,
      )
    })
  } else {
    const root = createRoot(rootElement)
    root.render(
      <Provider store={store}>
        <HeadProvider>
          <BrowserRouter>
            <App
              routes={[...routes, ...clientRoutes]}
              abTests={abTests}
              cloudfrontViewerCountry={window.CLOUDFRONT_VIEWER_COUNTRY}
            />
          </BrowserRouter>
        </HeadProvider>
      </Provider>,
    )
  }
})
