import api from '../api'
export const getUserCountry = () => {
  const fallback = 'US'

  return api('GET', 'v2/country')
    .then((result) =>
      result.response.country ? result.response.country : fallback,
    )
    .catch(() => fallback)
}
