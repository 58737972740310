import sha256 from './sha256'
import api from 'js/api'
import { MAINTAIN_WEIGHT } from 'js/goals'
import { getUTMParams } from 'js/UTMManager'

import { clientId, clientSecret } from 'js/config'

export const createAccount = ({ weightUnit, heightUnit, ...userData }) => {
  const timestamp = Math.floor(Date.now() / 1000)
  const useskj = ['AU', 'NZ', 'ZA'].includes(userData.country)

  let usesmetric = false
  let usesstone = false

  if (heightUnit === 'cm' && weightUnit === 'kg') {
    usesmetric = true
    usesstone = false
  } else if (heightUnit === 'ft' && weightUnit === 'lbs') {
    usesmetric = false
    usesstone = false
  } else if (heightUnit === 'cm' && weightUnit === 'st') {
    usesmetric = true
    usesstone = true
  }

  const postData = {
    email: userData.email,
    pass: userData.password ? userData.password : '',
    gender: userData.gender,
    loseweight: parseInt(userData.goal, 10),
    weight: parseInt(userData.weight, 10),
    height: parseInt(userData.height, 10),
    targetweight:
      userData.goal === MAINTAIN_WEIGHT
        ? parseInt(userData.weight, 10)
        : parseInt(userData.goalWeight, 10),
    useskj,
    usesmetric,
    usesstone,
    firstname: userData.firstName,
    lastname: userData.lastName ? userData.lastName : '',
    timestamp: timestamp,
    country: userData.country,
    api_key: clientId,
    device: 'web',
    activity: 1.375, // Same default as apps
    lossperweek: 0.5, // Same default as apps
    birthdate: new Date().getUTCFullYear() - userData.age + '-01-01',
    privacy_policy_accepted: true,
  }

  if (userData.oauth_service && userData.oauth_token) {
    postData['oauth_service'] = userData.oauth_service
    postData['oauth_token'] = userData.oauth_token
  }

  if (getUTMParams()) {
    postData['web_attribution'] = getUTMParams()
  }

  return sha256(`$${userData.email}$${timestamp}$${clientSecret}`).then(
    (hash) =>
      api('POST', 'v2/accounts/create', { hash, ...postData }).then(
        ({ response: { userid } }) => ({
          userid,
        }),
      ),
  )
}
