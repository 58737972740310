import { ga, taboola, facebook, outbrain } from 'js/analytics/adapters'

export const trackPageView = () => {
  ga.page({
    url: document.location.href,
    pathname: document.location.pathname,
    title: document.title,
  })
  taboola.page()
  facebook.page()
  outbrain.page()
}
