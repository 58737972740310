import { getItem } from 'js/storeManager'
import createAdapter from './createAdapter'
export const taboola = createAdapter(
  () => {
    const acceptedCookies = getItem('accepted-cookies')
      ? JSON.parse(getItem('accepted-cookies') as string)
      : []
    return acceptedCookies.includes('marketing')
  },
  {
    track(eventName: string) {
      const img = document.createElement('img')
      img.setAttribute(
        'src',
        `https://trc.taboola.com/1157532/log/3/unip?en=${eventName}`,
      )
      img.setAttribute('width', '0')
      img.setAttribute('height', '0')
      img.setAttribute('style', 'display:none')
      img.setAttribute('referrerPolicy', 'no-referrer-when-downgrade')
      document.getElementsByTagName('body')[0].appendChild(img)
    },
    page() {
      this.track('page_view')
    },
  },
)
