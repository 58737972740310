export default function sha256(message) {
  // Use polyfill/package in old browsers and in testcafe
  // testcafe has a strange bug where crypto.subtle never finishes so the tests
  // never get passed the call to createAccount that uses this sha256 method
  if (
    navigator.userAgent.match(/DisableForTesting/) ||
    typeof window.crypto === 'undefined' ||
    typeof window.crypto.subtle === 'undefined'
  ) {
    return import(/* webpackChunkName: "sha256-polyfill" */ 'jshashes').then(
      ({ SHA256 }) => Promise.resolve(new SHA256().hex(message)),
    )
  }

  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  return crypto.subtle.digest('SHA-256', msgBuffer).then((hashBuffer) => {
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
    return hashHex
  })
}
