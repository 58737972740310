import { ga } from 'js/analytics/adapters'

type Props = {
  type: string
  code?: string
  category: string
}
export const trackErrorMessage = ({
  type: error_type,
  code: error_code,
  category: error_category,
}: Props) => {
  ga.track('Error', {
    error_code: error_code,
    error_type: error_type,
    error_category: error_category,
  })
}
