import React from 'react'
import PropTypes from 'prop-types'
import { isSSR } from './isSSR'

export default class SentryBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    error: null,
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === 'development') {
      this.setState({ error })
      console.error(error)
    }
    if (typeof Sentry === 'undefined' && !isSSR) {
      document.dispatchEvent(new Event('loadTracking'))
      return window.sentryQueue.push({ error })
    }

    if (
      error.message.match(
        /^SecurityError: Blocked a frame with origin "https:\/\/lifesum.com"/,
      )
    ) {
      // Ignore 99% of these
      if (Math.floor(Math.random() * 100) !== 0) {
        return null
      }
    }

    Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div className="bg-red px-8 py-24 font-demibold text-white">
            {this.state.error.toString()}
          </div>
          {this.props.children}
        </div>
      )
    }
    return this.props.children
  }
}
