/**
 *
 * LanguageProvider.js
 *
 * This component connects the user's locale to the IntlProvider component and provides the application
 * the i18n messages (found in the translations folder).
 */

import React, { useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { importLanguage } from '../i18n'
import useLocale from '../hooks/useLocale'

type Props = {
  children: React.ReactNode
  defaultMessages?: {}
}
const LanguageProvider = ({ children, defaultMessages }: Props) => {
  const locale = useLocale()
  const [language, setLanguage] = useState(locale.language)
  const [translationMessages, setTranslationMessages] = useState(
    defaultMessages && defaultMessages[language] ? defaultMessages : undefined,
  )

  const changeLanguage = (language) => {
    importLanguage(language).then((messages) => {
      setTranslationMessages({
        ...translationMessages,
        [language]: messages,
      })
      setLanguage(language)
    })

    if (typeof document !== 'undefined') {
      document.documentElement.setAttribute('lang', language)
    }
  }

  useEffect(() => {
    if (!translationMessages || locale.language !== language) {
      changeLanguage(locale.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale.language])

  if (!language || !translationMessages || !translationMessages[language]) {
    return null
  }

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={translationMessages[language]}
    >
      {React.Children.only(children)}
    </IntlProvider>
  )
}

export default LanguageProvider
