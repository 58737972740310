/* global AppleID */
import api from 'js/api'
import { appleRedirectUri } from './config'

type GateKeeperAppleResponse = {
  access_token: string
  expires_at: number
}

export function login() {
  return new Promise<GateKeeperAppleResponse>((resolve, reject) => {
    const appleIdAuthScript = document.createElement('script')

    appleIdAuthScript.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    appleIdAuthScript.type = 'text/javascript'
    appleIdAuthScript.addEventListener('load', function () {
      const state =
        new Date().getTime().toString() +
        Math.random().toString(20).substring(2)

      AppleID.auth.init({
        clientId: 'signInWithApple.com.sillens.iShape',
        redirectURI: appleRedirectUri,
        state,
        usePopup: true,
      })

      AppleID.auth
        .signIn()
        .then((data) => {
          const {
            code,
            id_token: token,
            state: returnedState,
          } = data.authorization
          if (state !== returnedState) {
            throw new Error(
              'state is not the same as in the request: is it a hack attempt?',
            )
          }
          return { code, token }
        })
        .then(({ code, token }) => {
          return api('POST', 'gatekeeper/v1/login/apple', {
            code,
            token,
          })
        })
        .then(({ access_token, expires_at }: GateKeeperAppleResponse) => {
          resolve({
            access_token,
            expires_at,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })

    document.head.appendChild(appleIdAuthScript)
  })
}
