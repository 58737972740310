import React, { createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ga } from 'js/analytics/adapters'

const Context = createContext()

export const AbTestProvider = ({ abTests, children }) => (
  <Context.Provider value={abTests}>{children}</Context.Provider>
)
AbTestProvider.propTypes = {
  abTests: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export const useAbTest = (experimentId, shouldTrack = true) => {
  const abTests = useContext(Context)
  const variant = abTests[experimentId]
  const active = variant !== undefined

  useEffect(() => {
    if (active && shouldTrack) {
      ga.set('exp', `${experimentId}.${variant}`)
      ga.track('exp_activate')
    }
    // FIXME: dont ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrack])

  return {
    active,
    variant,
  }
}
