import React, { useState } from 'react'
import { setItem, getItem } from 'js/storeManager'
import { isSSR } from 'js/isSSR'
import { FormattedMessage } from 'react-intl'
import { clearAllCookies, GetPolicyLink } from '../../utils'
import CloseIcon from 'media/close.svg?svgr-webpack'
import Overlay, { OverlayWrapper } from '../Overlay/Overlay'
import { useLocation } from 'react-router-dom'
import LSButton from '../LSButton'
import Link from '../Link'

type InfoProps = {
  onDone: OnDoneFunction
  onSettings: () => void
}

const Info = ({ onDone, onSettings }: InfoProps) => {
  const { pathname } = useLocation()

  return (
    <div tabIndex={-1} data-autofocus className="focus:outline-none">
      <h2 className="type-upper-fat mb-8">
        <FormattedMessage id="cookies_heading" defaultMessage="Cookies" />
      </h2>
      <p className="type-small mb-32 mr-8 text-left leading-body md:mr-auto">
        <FormattedMessage
          id="cookies_text_privacy_policy"
          defaultMessage="We use cookies to recognize your preference and improve the website experience by collecting information about bugs, analyzing usage data and customize ads. If you allow all cookies, it may mean that some data will be shared with third parties, such as our marketing partners. You can adjust your cookie preferences at any time at the bottom of this website. For more information on how we use cookies, please see our <a>Privacy Policy</a>."
          values={{
            a: function A(text) {
              return (
                <Link
                  href={`${
                    pathname.match(/disclaimer|nutrition-explained/i)
                      ? '/privacy-policy.html'
                      : `${GetPolicyLink()}#cookies`
                  }`}
                >
                  {text}
                </Link>
              )
            },
          }}
        />
      </p>
      <div className="mb-20">
        <LSButton
          link={false}
          className="mb-16 w-full"
          onClick={() => onDone(['analytics', 'marketing'])}
          variant="primary"
        >
          <FormattedMessage
            id="cookies_allow_all"
            defaultMessage="Allow all cookies"
          />
        </LSButton>
        <LSButton
          link={false}
          className="w-full"
          onClick={() => onDone([], false)}
          variant="secondary"
        >
          <FormattedMessage
            id="cookies_necessary"
            defaultMessage="Use necessary cookies only"
          />
        </LSButton>
      </div>
      <button
        className={`
        type-small-fat border-b-2 border-Type
        text-Type transition hover:border-TypeSub hover:text-TypeSub
        focus:border-TypeSub focus:text-TypeSub active:border-black active:text-black
        `}
        onClick={onSettings}
      >
        <FormattedMessage id="cookies_options" defaultMessage="More options" />
      </button>
    </div>
  )
}

type CookieConsentOptions = 'required' | 'analytics' | 'marketing'
type OnDoneFunction = (
  acceptedCookies: CookieConsentOptions[],
  loadTracking?: boolean,
) => void

type CheckboxProps = {
  type: CookieConsentOptions
  onChange: () => void
  title: React.ReactNode
  text: React.ReactNode
  disabled?: boolean
  defaultChecked: boolean
}

const Checkbox = ({
  title,
  text,
  type,
  disabled,
  onChange,
  defaultChecked,
}: CheckboxProps) => {
  return (
    <div className="c-check mb-24">
      <input
        className="c-check__input"
        type="checkbox"
        id={`${type}-cookies`}
        onChange={onChange}
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
      <label className="c-check__label" htmlFor={`${type}-cookies`}>
        <span className="font-demibold">{title}</span>
        <span className="mt-4 block text-TypeSub">{text}</span>
      </label>
    </div>
  )
}

type SettingsProps = {
  onDone: OnDoneFunction
  shouldLoadSettings: boolean
}

const Settings = ({ onDone, shouldLoadSettings }: SettingsProps) => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    JSON.parse(getItem('accepted-cookies') || 'null') || [],
  )

  const handleChange = (type) => {
    if (acceptedCookies.includes(type)) {
      setAcceptedCookies(acceptedCookies.filter((cookie) => cookie !== type))
    } else {
      setAcceptedCookies([...acceptedCookies, type])
    }
  }

  const handleAllowSelected = () => {
    if (shouldLoadSettings) {
      const state = getItem('state')
      clearAllCookies()
      sessionStorage.clear()
      localStorage.clear()
      setItem('accepted-cookies', JSON.stringify(acceptedCookies))
      if (state) setItem('state', state)
      location.reload()
    } else {
      onDone(acceptedCookies)
    }
  }

  return (
    <form className="type-small text-left">
      <Checkbox
        title={
          <FormattedMessage
            id="cookies_required_title"
            defaultMessage="Strictly necessary cookies"
          />
        }
        text={
          <FormattedMessage
            id="cookies_required_text"
            defaultMessage="These cookies are required for optimal website functionality."
          />
        }
        type="required"
        onChange={() => {}}
        disabled={true}
        defaultChecked={true}
      />
      <Checkbox
        title={
          <FormattedMessage
            id="cookies_analytics_title"
            defaultMessage="Performance and analytics cookies"
          />
        }
        text={
          <FormattedMessage
            id="cookies_analytics_text"
            defaultMessage="Allow us to measure usage in order to analyze and improve the site."
          />
        }
        type="analytics"
        onChange={() => handleChange('analytics')}
        defaultChecked={acceptedCookies.includes('analytics')}
      />
      <Checkbox
        title={
          <FormattedMessage
            id="cookies_marketing_title"
            defaultMessage="Marketing cookies"
          />
        }
        text={
          <FormattedMessage
            id="cookies_marketing_text"
            defaultMessage="Measures the performance of campaigns and customize ads."
          />
        }
        type="marketing"
        onChange={() => handleChange('marketing')}
        defaultChecked={acceptedCookies.includes('marketing')}
      />
      <div className="mt-32">
        <LSButton
          link={false}
          variant="primary"
          onClick={() => handleAllowSelected()}
          type="button"
          className="mb-16 w-full"
        >
          <FormattedMessage
            id="cookies_allow_selected"
            defaultMessage="Allow selected"
          />
        </LSButton>
        <LSButton
          className="w-full"
          link={false}
          variant="secondary"
          onClick={() => onDone(['analytics', 'marketing'])}
          type="button"
        >
          <FormattedMessage
            id="cookies_allow_all"
            defaultMessage="Allow all cookies"
          />
        </LSButton>
      </div>
    </form>
  )
}

type Props = {
  shouldLoadSettings?: boolean
  onClose?: () => void
  forceServerSideRendering?: boolean
}

const CookieConsent = ({
  forceServerSideRendering = false,
  shouldLoadSettings = false,
  onClose,
}: Props) => {
  const [hasMadeAChoice, setHasMadeAChoice] = useState(
    getItem('accepted-cookies') && !shouldLoadSettings,
  )
  const [showSettings, setShowSettings] = useState(shouldLoadSettings)

  if (isSSR && !forceServerSideRendering) return null
  if (!isSSR && navigator.userAgent.match('DisableForTesting')) {
    return null
  }
  if (hasMadeAChoice) return null

  const onDone: OnDoneFunction = (acceptedCookies, loadTracking = true) => {
    setHasMadeAChoice(true)
    setItem('accepted-cookies', JSON.stringify(acceptedCookies))
    if (loadTracking) document.dispatchEvent(new CustomEvent('loadTracking'))
  }

  let OverlayComponent: typeof Overlay | typeof OverlayWrapper = Overlay
  if (forceServerSideRendering) {
    OverlayComponent = OverlayWrapper
  }

  return (
    <OverlayComponent>
      <div
        className={`
        absolute bottom-0 left-0 right-0 border-Border
        bg-white px-20
        pb-24 pt-56
        text-center text-14 shadow-elevated
        md:right-auto md:mx-24 md:mb-24
        md:w-1/2 md:max-w-480
        md:rounded-8 md:border-1 lg:text-17
      `}
      >
        {shouldLoadSettings && onClose && (
          <button
            type="button"
            className="absolute right-0 top-0 mr-20 mt-20 text-grey-light"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <CloseIcon />
          </button>
        )}
        {showSettings ? (
          <Settings onDone={onDone} shouldLoadSettings={shouldLoadSettings} />
        ) : (
          <Info onDone={onDone} onSettings={() => setShowSettings(true)} />
        )}
      </div>
    </OverlayComponent>
  )
}

export default CookieConsent
