import { defineMessages } from 'react-intl'

export default defineMessages({
  incorrect: {
    id: 'Login.incorrect',
    defaultMessage: 'The email or password is unfortunately incorrect',
  },
  invalidEmail: {
    id: 'Login.invalidEmail',
    defaultMessage: 'Please check that you have entered a valid email',
  },
  facebookNotConnected: {
    id: 'Login.facebookNotConnected',
    defaultMessage:
      "Your Facebook and Lifesum accounts aren't connected. Connect them via your profile in the app or sign in with an email and password instead.",
  },
  facebookWentWrong: {
    id: 'Login.facebookWentWrong',
    defaultMessage:
      'Something went wrong when trying to log you in via Facebook. Try again!',
  },
  appleNotConnected: {
    id: 'Login.appleNotConnected',
    defaultMessage: 'Your Apple account is not connected to Lifesum',
  },
  appleWentWrong: {
    id: 'Login.appleWentWrong',
    defaultMessage: 'Something went wrong when signing in with Apple',
  },
  googleNotConnected: {
    id: 'Login.googleNotConnected',
    defaultMessage: 'Your Google account is not connected to Lifesum',
  },
  googleWentWrong: {
    id: 'Login.googleWentWrong',
    defaultMessage: 'Something went wrong when signing in with Google',
  },
  allowPopups: {
    id: 'Login.allowPopups',
    defaultMessage:
      'Please allow popups for this page in your browser and try again.',
  },
  wentWrong: {
    id: 'Login.wentWrong',
    defaultMessage:
      'Something went wrong when trying to log you in. Try again!',
  },
  deleteWentWrong: {
    id: 'Account.deleteWentWrong',
    defaultMessage:
      'Something went wrong when trying to delete your account. Please try again or contact us if the problem persists.',
  },
  cancelError: {
    id: 'Account.cancelError',
    defaultMessage:
      'Something went wrong when trying to cancel your subscription. Please try again or contact us.',
  },
  createError: {
    id: 'Account.createError',
    defaultMessage:
      'Something went wrong when creating your account. Please try again or contact us if the problem persists.',
  },
  verifyError: {
    id: 'Account.verifyError',
    defaultMessage:
      'Something went wrong, and it’s our fault. Sorry about that. If the problem persist, please contact us.',
  },
  updateError: {
    id: 'Account.verifyError',
    defaultMessage:
      'Something went wrong, and it’s our fault. Sorry about that. If the problem persist, please contact us.',
  },
})
