import * as fb from 'js/facebook'
import api from 'js/api'
import { debug } from 'js/utils/debug'

function authenticateWithFacebook(token) {
  return api('POST', 'gatekeeper/v1/login/facebook', {
    token,
  }).then(({ access_token, expires_at, refresh_token }) => ({
    access_token,
    expires_at,
    refresh_token,
  }))
}

export const loginWithFacebook = (token?: string, shouldDebug?: boolean) => {
  if (token) return authenticateWithFacebook(token)

  return fb.login(shouldDebug).then(({ token }) => {
    debug(`loginWithFacebook got token from fb.login: ${token}`)
    return authenticateWithFacebook(token)
  })
}
