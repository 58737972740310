// Variables are set in .env
export const apiHost = process.env.LIFESUM_API_HOST
export const adjustIosSmartBannerAppToken =
  process.env.ADJUST_IOS_SMART_BANNER_APP_TOKEN
export const adjustAndroidSmartBannerAppToken =
  process.env.ADJUST_ANDROID_SMART_BANNER_APP_TOKEN
export const webAnalyticsHost = process.env.WEB_ANALYTICS_HOST
export const gaTrackingId = process.env.GOOGLE_ANALYTICS_ID
export const googleTagManagerId = process.env.GOOGLE_TAG_MANAGER_ID
export const facebookLoginAppId = process.env.FACEBOOK_LOGIN_APP_ID
export const facebookTrackingAppId = process.env.FACEBOOK_TRACKING_APP_ID
export const facebookPublicAppId = process.env.FACEBOOK_PUBLIC_APP_ID
export const adyenOriginKey = process.env.ADYEN_ORIGIN_KEY
export const environment = process.env.environment
export const appleRedirectUri = process.env.APPLE_REDIRECT_URI
export const datoToken = process.env.DATO_TOKEN
export const ga4MeasurementId = process.env.GA4_MEASUREMENT_ID
export const gaLifesumAppsPropertyId = process.env.GA_LIFESUM_APPS_PROPERTY_ID
export const clientId = process.env.CLIENT_ID
export const clientSecret = process.env.CLIENT_SECRET
export const googleLoginId = process.env.GOOGLE_LOGIN_ID
