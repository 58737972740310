import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getPricing } from '../pricing'
import { loadState, migrate } from './localStorage'
import { selectCurrency } from './localeSlice'
import { CurrencyCodes } from 'js/locale'

const defaultState = {
  isLoading: false,
  error: undefined,
  couponErrors: {},
  coupon: undefined,
  currencies: [],

  // The coupon the user has actively selected. `null` means
  // that the user actively selected the regular price
  selectedCoupon: undefined,

  configs: [
    // {
    //   discount: undefined,
    //   currency: undefined,
    //   products: [],
    // },
  ],
  __version: 2,
}

const previousState = loadState()

export const migrations = {
  1: () => {
    return {}
  },
  2: () => {
    return {}
  },
}

const initialState = {
  ...Object.assign(
    {},
    defaultState,
    previousState && previousState.pricing
      ? migrate(migrations)(previousState.pricing)
      : {},
  ),
  configs: [],
}

const slice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    reset() {
      return defaultState
    },
    setCoupon(state, { payload: coupon }: { payload: string }) {
      state.selectedCoupon = coupon
    },
    setCouponError(
      state,
      {
        payload: { coupon, error },
      }: { payload: { coupon: string; error: string } },
    ) {
      state.couponErrors[coupon] = error
    },
    clearCouponError(state, { payload: coupon }: { payload: string }) {
      delete state.couponErrors[coupon]
    },
    setCouponButDoNotOverride(state, { payload: coupon }) {
      if (state.selectedCoupon) return
      state.selectedCoupon = coupon
    },
    clearCoupon(state) {
      state.selectedCoupon = undefined
    },
    setCurrencies(state, { payload: newCurrencies }) {
      state.currencies = newCurrencies
    },
    addConfig(state, { payload: newConfig }) {
      const foundIndex = newConfig.coupon
        ? state.configs.findIndex((cfg) => cfg.coupon === newConfig.coupon)
        : state.configs.findIndex((cfg) => !cfg.coupon)

      if (foundIndex > -1) {
        state.configs[foundIndex] = newConfig
      } else {
        state.configs.push(newConfig)
      }
    },
    pricingRequest(state) {
      state.isLoading = true
      state.error = undefined
    },
    pricingSuccess(state) {
      state.isLoading = false
      state.error = undefined
    },
    pricingFailure(state, { payload: errorMessage }) {
      state.isLoading = false
      state.error = errorMessage
    },
  },
})

export const {
  reset,
  setCoupon,
  clearCoupon,
  setCouponError,
  clearCouponError,
  addConfig,
  pricingRequest,
  pricingSuccess,
  pricingFailure,
  setCurrencies,
  setCouponButDoNotOverride,
} = slice.actions

// -----------------------------
// SELECTORS
// -----------------------------

const selectPricing = (state) => state.pricing

const selectPricingConfigs = createSelector(
  [selectPricing],
  ({ configs }) => configs,
)

export const selectCoupon = createSelector(
  [selectPricing],
  ({ selectedCoupon }) => selectedCoupon as string | undefined | null,
)

export const selectCouponError = (coupon) =>
  createSelector([selectPricing], ({ couponErrors }) => couponErrors[coupon])

export const selectCurrencies = createSelector(
  [selectPricing],
  ({ currencies }) => currencies,
)

export const selectPricingConfig = (forceCoupon?: string) =>
  createSelector(
    [selectCoupon, selectPricingConfigs],
    (selectedCoupon, configs) => {
      if (forceCoupon === null) {
        return configs.find((cfg) => cfg.coupon === null)
      }
      if (forceCoupon) {
        return configs.find((cfg) => cfg.coupon === forceCoupon)
      } else if (typeof selectedCoupon !== 'undefined') {
        return configs.find((cfg) => cfg.coupon === selectedCoupon)
      }

      // Find the config with the best discount
      const sorted = configs.slice().sort((a, b) => {
        if (!a.discount) return -1
        if (!b.discount) return 1
        return a.discount - b.discount
      })
      return sorted.reverse()[0]
    },
  )

export const selectRegularPricingConfig = createSelector(
  [selectPricingConfigs],
  (configs) => {
    return configs.find((cfg) => !cfg.coupon)
  },
)

// -----------------------------
// ASYNC ACTIONS
// -----------------------------

export const fetchPricing =
  (coupon, forceCurrency?: CurrencyCodes) => (dispatch, getState) =>
    new Promise<void>((resolve, reject) => {
      dispatch(pricingRequest())

      const currency = forceCurrency
        ? forceCurrency
        : selectCurrency(getState())

      if (typeof coupon !== 'undefined') {
        const config = selectPricingConfig(coupon)(getState())
        if (config && config.currency === currency) {
          // Avoid calling the api if coupon and currency haven't changed
          dispatch(pricingSuccess())
          resolve()
          return
        }
      }

      // TODO: don't fetch multiple times at once
      getPricing(coupon)
        .then((config) => {
          const products = (
            coupon
              ? config.products
              : config.products.filter((product) => product.duration < 24)
          )
            .sort((a, b) => a.duration - b.duration)
            .map((product) => ({
              duration: product.duration,
              price: product.price,
              originalPrice: product.original_price,
              pricePerMonth: product.price_per_month,
              discount: config.discount,
              discountType: config.discountType,
            }))

          dispatch(
            addConfig({
              products,
              coupon: coupon || null,
              discount: config.discount,
              currency: config.currency,
              trialDuration: config.trialDuration,
              discountType: config.discountType,
              discountOnlyFirstPurchase: config.discountOnlyFirstPurchase,
            }),
          )

          if (config.discountType !== '%') {
            dispatch(setCurrencies([config.discountType]))
          }

          if (!coupon && config.availableCurrencies) {
            dispatch(setCurrencies(config.availableCurrencies))
          }

          dispatch(pricingSuccess())
          if (typeof coupon !== 'undefined') {
            dispatch(clearCouponError(coupon))
          }

          resolve()
        })
        .catch((error) => {
          dispatch(pricingFailure(error))
          if (typeof coupon !== 'undefined') {
            dispatch(setCouponError({ coupon, error }))
          }
          reject(error)
        })
    })

// -----------------------------
// UTILITIES
// -----------------------------

type Plan = {
  duration: number
  pricePerMonth: number
  originalPrice: number
}
export const getPlanWithLowestMonthlyPrice = (products: Plan[] = []) => {
  const plan = products
    .slice()
    .sort((a, b) => a.pricePerMonth - b.pricePerMonth)[0]
  return plan
}

const reducer = slice.reducer
export { initialState, reducer }
