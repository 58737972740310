import React, { useEffect, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { saveUTMParams } from '../UTMManager'
import { useLocation } from 'react-router-dom'
import { trackPageView } from '../analytics'
import { useDispatch } from 'react-redux'
import { fetchLimitedAccountInfo } from '../store/userSlice'

const PageviewContext = React.createContext()

export default function RouteChangeHandler({ children }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const [category, setCategory] = useState()
  const locationRef = useRef()

  useEffect(() => {
    if (location.pathname !== locationRef.current) {
      window.scrollTo(0, 0)
    }

    const timeout = setTimeout(() => {
      trackPageView(category)
    }, 500)
    saveUTMParams()

    const urlParams = new URLSearchParams(document.location.search)
    const userId = urlParams.get('user_id')
    if (userId) {
      dispatch(fetchLimitedAccountInfo(userId))
    }

    locationRef.current = location.pathname

    return () => {
      clearTimeout(timeout)
    }
  }, [location.pathname, category, dispatch])

  return (
    <PageviewContext.Provider value={{ category, setCategory }}>
      {React.Children.only(children)}
    </PageviewContext.Provider>
  )
}

RouteChangeHandler.propTypes = {
  children: PropTypes.node,
}

export function usePageCategory(category) {
  const { setCategory } = useContext(PageviewContext)

  useEffect(() => {
    setCategory(category)
    return () => setCategory(undefined)
    // FIXME: dont ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function withPageViewCategory(category) {
  const WithPageCategory = function WithPageCategory(PageComponent) {
    function PageViewCategory(props) {
      const { setCategory } = useContext(PageviewContext)

      useEffect(() => {
        setCategory(category)
        return () => setCategory(undefined)
      }, [setCategory])

      return <PageComponent {...props} />
    }

    PageViewCategory.displayName = 'Home'

    return PageViewCategory
  }

  WithPageCategory.displayName = 'Home'

  return WithPageCategory
}
