import cn from 'classnames'
import React from 'react'

type Props = {
  href: string
  children: React.ReactNode
  className?: string
} & React.ComponentProps<'a'>

const Link = ({ href, children, className }: Props) => {
  return (
    <a
      href={href}
      className={cn(
        `
    border-b-1 border-LSWaterBase/25 text-LSWaterBase transition
    hover:border-LSLinkHover/100 hover:text-LSLinkHover focus:border-LSLinkHover/100
    focus:text-LSLinkHover active:border-LSLinkActive/100
    active:text-LSLinkActive
    `,
        className,
      )}
    >
      {children}
    </a>
  )
}

export default Link
