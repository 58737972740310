import { createSlice, createSelector } from '@reduxjs/toolkit'
import { loadState } from './localStorage'
import {
  getUserLanguage,
  getCurrencyFromCountry,
} from '../locale/localizationHelper'
import { isSSR } from 'js/isSSR'
import { languages, language as allLanguages } from '../locale'
import { getUserCountry } from '../auth'
import Cookies from 'js-cookie'
import { languageCookieKey } from 'lifesum-ssr/server/shared'
const defaultState = {
  language: null,
  currency: null,
  country: null,
}

const previousState = loadState() || {}

let userLanguage
if (!isSSR) {
  const storedLanguage = Cookies.get(languageCookieKey)
  userLanguage = getUserLanguage({
    stored: storedLanguage,
    pathname: document.location.pathname,
    acceptedLanguages: navigator.languages,
  })
  Cookies.set(languageCookieKey, userLanguage)
}

const slice = createSlice({
  name: 'locale',
  initialState: {
    ...defaultState,
    ...previousState.locale,
    ...(!isSSR && {
      ...window.PRELOADED_STATE?.locale,
      language: userLanguage,
    }),
  },
  reducers: {
    setLanguage(state, { payload: language }) {
      Cookies.set(languageCookieKey, language)
      state.language = language
    },
    setCurrency(state, { payload: currency }) {
      state.currency = currency
    },
    setCountry(state, { payload: country }) {
      state.country = country
    },
  },
})

export const { reducer } = slice
export const { setCountry, setCurrency } = slice.actions

export const detectInitialLocale = (navigate) => (dispatch, getState) =>
  new Promise<void>((resolve) => {
    const {
      locale: { country, currency, language },
    } = getState()
    if (!country) {
      getUserCountry().then((country) => {
        dispatch(setCountry(country))

        if (!currency) {
          dispatch(setCurrency(getCurrencyFromCountry(country)))
        }
      })
    }

    if (!currency && country) {
      dispatch(setCurrency(getCurrencyFromCountry(country)))
    }

    const pathname = document.location.pathname

    // Add language slugs to url
    if (
      language !== allLanguages.ENGLISH &&
      (pathname === '/' || pathname === '')
    ) {
      navigate(`/${language}/`, { replace: true })
    }

    // Remove /en/ from URL
    if (
      (pathname.length === 3 && pathname.toLowerCase().slice(0, 3) === '/en') ||
      pathname.toLowerCase().slice(0, 4) === '/en/'
    ) {
      navigate(pathname.slice(3), { replace: true })
    }

    resolve()
  })

/** Set the language of the page
 * @param newLanguage {String} The new language
 * @param redirect {Boolean} Should the user be redirected to the start page after language switch?
 */
export const setLanguage =
  (newLanguage, redirect = true, navigate = (_path, _options = {}) => {}) =>
  (dispatch) =>
    new Promise<void>((resolve, reject) => {
      if (!languages.includes(newLanguage)) reject()

      if (typeof document !== 'undefined') {
        document.documentElement.setAttribute('lang', newLanguage)
      }

      if (redirect) {
        if (!navigate) {
          throw new Error(
            'setLanguage was called with redirect=true but without navigate which is needed',
          )
        }
        navigate(newLanguage === allLanguages.ENGLISH ? '/' : `/${newLanguage}`)
      }

      dispatch(slice.actions.setLanguage(newLanguage))
      resolve()
    })

const selectLocale = (state) => state.locale

export const selectLanguage = createSelector(
  [selectLocale],
  ({ language }) => language,
)

export const selectCurrency = createSelector(
  [selectLocale],
  ({ currency }) => currency,
)

export const selectCountry = createSelector(
  [selectLocale],
  ({ country }) => country,
)
