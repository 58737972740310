import { language, languages, currency, LanguageCodes } from './constants'

const isValidLanguage = (lang) => {
  return languages.includes(lang)
}

/**
 * Method to determine the preferred language for the user.
 * @returns a string describing the user's preferred locale, based on browser
 * info and url
 */
type GetUserLanguageProps = {
  stored: undefined | string
  pathname: string
  acceptedLanguages: readonly string[]
}

export const getSlugLanguage = (pathname: string): LanguageCodes | null => {
  const slugLanguageMatch = pathname.match(
    new RegExp(`/(${languages.join('|')})(/.*)?$`, 'i'),
  )
  if (slugLanguageMatch) {
    return slugLanguageMatch[1].toLowerCase() as LanguageCodes
  }
  return null
}

export const getUserLanguage = ({
  stored,
  pathname,
  acceptedLanguages = [],
}: GetUserLanguageProps): string => {
  const slugLanguage = getSlugLanguage(pathname)
  if (slugLanguage) {
    return slugLanguage
  }

  if (pathname.match(/^\/news\//)) {
    // for legacy reasons if the /news/<slug> slug starts with any of
    // the following keys (case insensitive) the language is set to the matching
    // value
    const localeIdentifierMappings = {
      'die-': 'de',
      'dk-': 'da',
      'se-': 'sv',
      'br-': 'pt',
    }
    const customLanguagePrefixMatch = pathname.match(
      new RegExp(
        `/news/(${Object.keys(localeIdentifierMappings).join('|')})`,
        'i',
      ),
    )
    if (customLanguagePrefixMatch) {
      return localeIdentifierMappings[
        customLanguagePrefixMatch[1].toLowerCase()
      ]
    }

    // or if it starts with a language code and a dash like e.g. it-
    const languagePrefixMatch = pathname.match(
      new RegExp(`/news/(${languages.join('|')})-`, 'i'),
    )
    if (languagePrefixMatch) {
      return languagePrefixMatch[1].toLowerCase()
    }

    return 'en'
  }

  if (stored) return stored

  const browserLanguage = acceptedLanguages
    .map((language) => language.toLowerCase().substr(0, 2))
    .map((language) => (language === 'nb' ? 'no' : language))
    .find((language) => isValidLanguage(language))

  if (browserLanguage) return browserLanguage

  return language.ENGLISH
}

export const getCurrencyFromCountry = (country: string): string => {
  // Uses ISO 3166 - 2 letter country codes
  return (
    {
      AD: currency.EUR,
      AL: currency.EUR,
      AT: currency.EUR,
      AX: currency.EUR,
      BA: currency.EUR,
      BE: currency.EUR,
      BG: currency.EUR,
      BY: currency.EUR,
      CH: currency.CHF,
      CY: currency.EUR,
      CZ: currency.EUR,
      DE: currency.EUR,
      DK: currency.DKK,
      EE: currency.EUR,
      ES: currency.EUR,
      FI: currency.EUR,
      FO: currency.EUR,
      FR: currency.EUR,
      GB: currency.GBP,
      GG: currency.EUR,
      GI: currency.EUR,
      GR: currency.EUR,
      HR: currency.EUR,
      HU: currency.EUR,
      IE: currency.EUR,
      IM: currency.EUR,
      IS: currency.EUR,
      IT: currency.EUR,
      JE: currency.EUR,
      LI: currency.EUR,
      LT: currency.EUR,
      LU: currency.EUR,
      LV: currency.EUR,
      MC: currency.EUR,
      MD: currency.EUR,
      ME: currency.EUR,
      MK: currency.EUR,
      MT: currency.EUR,
      NL: currency.EUR,
      NO: currency.NOK,
      PL: currency.EUR,
      PT: currency.EUR,
      RO: currency.EUR,
      RS: currency.EUR,
      RU: currency.EUR,
      SE: currency.SEK,
      SI: currency.EUR,
      SJ: currency.EUR,
      SK: currency.EUR,
      SM: currency.EUR,
      UA: currency.EUR,
      VA: currency.EUR,
      XK: currency.EUR,
      NZ: currency.NZD,
      AU: currency.AUD,
      BR: currency.BRL,
      MX: currency.MXN,
      CA: currency.CAD,
      IN: currency.INR,
    }[country] || currency.USD
  )
}
