import { facebookTracking } from 'js/featureFlags'
import { getUTMParams } from '../../UTMManager'
import createAdapter from './createAdapter'

export const facebook = createAdapter(() => !!window.fbq, {
  page() {
    if (!facebookTracking) {
      return
    }
    fbq('track', 'PageView')
  },
  track(eventName, parameters) {
    if (!facebookTracking) {
      return
    }

    fbq('track', eventName, parameters)

    if (eventName === 'Purchase' && getUTMParams()) {
      const utm = JSON.parse(getUTMParams() as string)

      if (utm.utm_campaign === 'relatable') {
        fbq('trackCustom', 'RelatablePurchase', {
          ...parameters,
          ...utm,
        })
      }
    }
  },
})
