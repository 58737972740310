export const captureException = (
  error,
  options: {
    context?
    fingerprint?: Array<string>
  } = {},
) => {
  if (typeof window['Sentry'] === 'undefined') {
    window.sentryQueue.push({ error })
    window.errorOutsideReact = true
    document.dispatchEvent(new Event('loadTracking'))
    return
  }

  if (options.fingerprint) {
    window['Sentry'].withScope((scope) => {
      scope.setFingerprint(options.fingerprint)
      window['Sentry'].captureException(error, options.context)
    })
  } else {
    window['Sentry'].captureException(error, options.context)
  }
}
