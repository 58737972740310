/**
 * i18n.js
 *
 * Sets up i18n language files for the application.
 */

export function importLanguage(language) {
  return new Promise((resolve) => {
    language = language.includes('pt') ? 'pt-BR' : language
    return import(`./translations/${language}.json`).then((module) =>
      resolve(module.default),
    )
  })
}
