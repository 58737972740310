/* global obApi */
import createAdapter from './createAdapter'
export const outbrain = createAdapter(() => !!window.obApi, {
  page() {
    obApi('track', 'PAGE_VIEW')
  },
  install() {
    obApi('track', 'installs')
  },
  track(eventName) {
    obApi('track', eventName)
  },
})
