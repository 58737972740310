import { isSSR } from 'js/isSSR'

export default (test, methods) => {
  // TODO: typescript hack: replace with something that only adds the adapters
  // actual methods
  const adapter = {} as { [key: string]: (...args: any[]) => void }
  if (
    (typeof navigator !== 'undefined' &&
      navigator.userAgent.match('DisableForTesting')) ||
    isSSR
  ) {
    Object.keys(methods).map((key) => {
      adapter[key] = () => {}
    })
  } else {
    const init = new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (test()) {
          resolve()
          clearInterval(interval)
        }
      }, 50)
    })

    Object.keys(methods).map((key) => {
      adapter[key] = (...params) => init.then(() => methods[key](...params))
    })
  }
  return adapter
}
