import { getSessionItem, setSessionItem } from './storeManager'

export const saveUTMParams = () => {
  if (document.location.search.indexOf('utm_') > -1) {
    const parameters = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ]
    const urlParams = new URLSearchParams(document.location.search)
    let utmData = {}

    for (let parameter of parameters) {
      if (urlParams.get(parameter)) {
        utmData[parameter] = urlParams.get(parameter)
      }
    }

    setSessionItem('utm', JSON.stringify(utmData))
  }
}

export const getUTMParams = () => {
  if (getSessionItem('utm')) {
    return getSessionItem('utm')
  } else {
    return false
  }
}
