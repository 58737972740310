import React, { useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'
import classNames from 'classnames'
import { isSSR } from 'js/isSSR'

type WrapperProps = {
  children: React.ReactNode
  backdropRef?: React.Ref<HTMLDivElement>
  contentRef?: React.Ref<HTMLDivElement>
}

export const OverlayWrapper = ({
  backdropRef,
  children,
  contentRef,
}: WrapperProps) => (
  <div
    ref={backdropRef}
    className={classNames(
      'fixed',
      'inset-0',
      'w-screen',
      'z-30',
      'h-full',
      'flex',
    )}
    style={{ animation: 'fade-to-black 0.5s forwards' }}
    data-testid="overlay-backdrop"
  >
    <div className="w-full overflow-y-scroll text-center">
      <div ref={contentRef} className="inline-block w-full py-24 lg:py-64">
        <FocusLock returnFocus>{children}</FocusLock>
      </div>
    </div>
  </div>
)

type Props = {
  children: React.ReactNode
  onOutsideClick?: () => void
}

const Overlay = ({ children, onOutsideClick }: Props) => {
  const backdropRef = useRef<HTMLDivElement>(null!)
  const contentRef = useRef<HTMLDivElement>(null!)
  useLayoutEffect(() => {
    const root = document.getElementById('mount') as HTMLElement
    const openClasses = ['overflow-y-hidden', 'h-screen']

    root.setAttribute('aria-hidden', 'true')

    window.scrollTo(0, 0)

    document.body.classList.add(...openClasses)

    if (onOutsideClick) {
      backdropRef.current.addEventListener('click', (event) => {
        if (
          document.body.contains(event.target as HTMLDivElement) &&
          !contentRef.current.contains(event.target as HTMLDivElement)
        ) {
          onOutsideClick()
        }
      })
    }

    const cleanup = () => {
      document.body.classList.remove(...openClasses)
      root.setAttribute('aria-hidden', 'false')

      if (onOutsideClick) {
        backdropRef.current.removeEventListener('click', onOutsideClick)
      }
    }

    return cleanup
  }, [onOutsideClick])

  if (isSSR) return null

  return ReactDOM.createPortal(
    <OverlayWrapper backdropRef={backdropRef} contentRef={contentRef}>
      {children}
    </OverlayWrapper>,
    document.getElementById('mount-modal') as HTMLElement,
  )
}

export default Overlay
