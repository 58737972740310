import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectCountry,
  selectCurrency,
  selectLanguage,
  setLanguage as setLocaleLanguage,
  setCountry as setLocaleCountry,
  setCurrency as setLocaleCurrency,
} from '../store/localeSlice'
import { LanguageCodes, CurrencyCodes } from 'js/locale/constants'
import { AppDispatch } from 'js/app'

export type LocaleProps = {
  language: LanguageCodes
  currency: string
  country: string
  setLanguage: () => void
  setCurrency: () => void
  setCountry: () => void
}

const useLocale = () => {
  const dispatch = useDispatch() as AppDispatch
  const navigate = useNavigate()
  const country = useSelector(selectCountry)
  const currency = useSelector(selectCurrency)
  const language = useSelector(selectLanguage)

  function setCurrency(currency: CurrencyCodes) {
    dispatch(setLocaleCurrency(currency))
  }

  // countries are defined in the i18n-iso-countries package. As a quick
  // typescript fix country's type is set to just string
  function setCountry(country: string) {
    dispatch(setLocaleCountry(country))
  }

  function setLanguage(language: LanguageCodes, redirect: boolean) {
    dispatch(setLocaleLanguage(language, redirect, navigate))
  }

  return {
    country,
    currency,
    language,
    setCountry,
    setCurrency,
    setLanguage,
  }
}

export default useLocale
