import { debug } from './utils/debug'

type LoginResponse = {
  token: string
}

export const login = async (shouldDebug?: boolean): Promise<LoginResponse> => {
  if (shouldDebug) {
    const authResponse = FB.getAuthResponse()
    if (authResponse !== null && authResponse.accessToken) {
      return { token: authResponse.accessToken }
    }
  }

  return new Promise<LoginResponse>((resolve, reject) => {
    FB.login(
      (res) => {
        if (shouldDebug) {
          debug(`FB.login callback status: ${res.status}`)
          debug(
            `FB.login callback authResponse: ${JSON.stringify(
              res.authResponse,
              null,
              2,
            )}`,
          )
        }

        if (res.authResponse && res.authResponse.accessToken) {
          resolve({ token: res.authResponse.accessToken })
        } else {
          reject(res)
        }
      },
      { scope: 'email' },
    )
  })
}

// @types/facebook-js-sdk didn't like the 'location.fields(location)' field
// so it thinks res is unknown
// So we have to manually type the response
type UserDetailsSuccessResponse = {
  first_name: 'string'
  last_name: 'string'
  email: 'string'
}

type UserDetailsErrorResponse = {
  error: any
}

type UserDetailsResponse = UserDetailsSuccessResponse & UserDetailsErrorResponse

export const getUserDetails = () =>
  new Promise<UserDetailsResponse>((resolve, reject) => {
    FB.api(
      '/me',
      {
        fields: ['first_name', 'last_name', 'email'],
      },
      (res: UserDetailsResponse) => {
        if (res && !res.error) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  })
