/* global Adjust */
import createAdapter from './createAdapter'
import { environment } from 'js/config'
import { getItem } from '../../storeManager'
export const adjust = createAdapter(
  () => !navigator.userAgent.match('DisableForTesting') && !!window.Adjust,
  {
    init() {
      Adjust.initSdk({
        appToken: '11gieguiu674',
        environment: environment === 'production' ? 'production' : 'sandbox',
        logLevel: environment === 'production' ? 'error' : 'info',
      })
    },
    track(event, id, revenue, currency) {
      let userId = id

      if (
        !userId &&
        getItem('state') &&
        JSON.parse(getItem('state') as string).user
      ) {
        const { user: userState } = JSON.parse(getItem('state') as string)
        userId = userState.profile?.id || userState.limitedProfile?.id
      }

      if (userId)
        Adjust.addGlobalCallbackParameters([
          { key: 'user_id', value: userId.toString() },
        ])

      if (event === 'Start Registration')
        Adjust.removeGlobalCallbackParameter('user_id')

      const eventTokens = {
        Purchase: 'a4kmyd',
        'Start Registration': 'z0kvpl',
        'Complete Registration': 'nmar37',
      }
      const data = {
        eventToken: eventTokens[event],
      }

      if (event === 'Purchase') {
        data['revenue'] = parseInt(revenue, 10)
        data['currency'] = currency
      }

      Adjust.trackEvent(data)
    },
    clearUser() {
      Adjust.removeGlobalCallbackParameter('user_id')
    },
  },
)
