import api from './api'

let currentCurrency = 'USD'

export const getPricing = (coupon) => {
  return new Promise((resolve, reject) => {
    const currency = currentCurrency

    const endpoint = coupon
      ? `v2/pricing/verify_coupon/${coupon}`
      : 'v2/pricing/products/web'

    api('GET', `${endpoint}?currency=${currency}`)
      .then((answer) => {
        const {
          currency,
          available_currencies: availableCurrencies = [],
          name: currencyName,
          discount = null,
          trial_duration: trialDuration = 0,
          discount_type: discountType = '%',
          discount_only_first_purchase: discountOnlyFirstPurchase,
        } = answer.response

        const products = answer.response.products
          .sort((a, b) => a.duration - b.duration)
          .map((plan) => ({
            ...plan,
            currency,
            currencyName,
            discount,
            discountType,
          }))

        const pricing = {
          coupon,
          products,
          currency,
          currencyName,
          availableCurrencies: availableCurrencies.filter(
            (currency) => currency !== 'EURO',
          ),
          discount,
          discountOnlyFirstPurchase,
          trialDuration,
          discountType,
        }

        resolve(pricing)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const verifyCoupon = (coupon) =>
  new Promise((resolve, reject) => {
    api('GET', `v2/pricing/verify_coupon/${coupon}`)
      .then(() => resolve())
      .catch((err) => reject(err))
  })

/**
 * @typedef GiftCardPricing
 * @property {bool} couponError
 * @property {object} product
 * @property {bool} discount
 * @property {string} currency
 */

/**
 * Get pricing for giftcard, which falls back to regular
 * pricing if coupon is invalid
 * @param {string} coupon
 * @returns {Promise<GiftCardPricing>}
 */
export const getGiftCardPricing = (coupon) => {
  return new Promise((resolve, reject) => {
    const getRegularPrices = () => {
      getPricing()
        .then(({ products, discount, currency }) => {
          const product = products.find((product) => product.duration === 12)

          if (product) {
            resolve({
              couponError: true,
              product,
              discount,
              currency,
            })
          } else {
            reject({ error: 'no 12 month subscription found' })
          }
        })
        .catch(() => reject({ error: 'api call failed' }))
    }

    getPricing(coupon)
      .then(({ products, discount, currency }) => {
        const product = products.find((product) => product.duration === 12)
        if (product) {
          resolve({
            product,
            discount,
            currency,
          })
        } else {
          getRegularPrices()
        }
      })
      .catch(() => {
        getRegularPrices()
      })
  })
}

export function setCurrency(currency) {
  currentCurrency = currency
}
