import {
  reducer as userReducer,
  setUserToken,
  initialState as userInitialState,
} from './userSlice'
import {
  reducer as pricingReducer,
  initialState as pricingInitialState,
} from './pricingSlice'
import {
  reducer as localeReducer,
  setCurrency as setLocaleCurrency,
} from './localeSlice'
import { setToken } from 'js/api'
import { setCurrency } from 'js/pricing'
import deepmerge from 'deepmerge'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'

const saveAuthToken =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { user } = getState()
    let authData: { accessToken: string; expiresAt: number }

    if (action.type === setUserToken.toString()) {
      authData = action.payload
    } else {
      authData = {
        accessToken: user.userToken,
        expiresAt: user.userTokenExpiration,
      }
    }

    setToken(authData)
    return next(action)
  }

const saveCurrency = (store) => (next) => (action) => {
  const { locale } = store.getState()
  if (action.type === setLocaleCurrency.toString()) {
    setCurrency(action.payload)
  } else if (locale.currency) {
    setCurrency(locale.currency)
  }

  return next(action)
}

function getStoreConfiguration(preloadedState = {}) {
  const persistedState = {
    pricing: pricingInitialState,
    user: userInitialState,
  }

  return {
    reducer: {
      // the reducers in the dispatch type isn't automatically updated, remember
      // to add/remove reducer keys below (see comment below for more details)
      user: userReducer,
      pricing: pricingReducer,
      locale: localeReducer,
    },
    initialState: deepmerge(preloadedState, persistedState),
    // The added type is needed to be able to get the right types on
    // store.dispatch. Remember to update the Type argument to
    // CurriedGetDefaultMiddleware when adding removing keys to the store object
    middleware: (
      getDefaultMiddleware: CurriedGetDefaultMiddleware<{
        user: any
        pricing: any
        locale: any
      }>,
    ) => getDefaultMiddleware().concat(saveAuthToken, saveCurrency),
  }
}

export { getStoreConfiguration }
