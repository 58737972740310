import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setSessionItem, getSessionItem } from 'js/storeManager'

const Context = createContext(true)

type ShowMenuProviderProps = {
  children: React.ReactNode
}

export const ShowMenuProvider = ({ children }: ShowMenuProviderProps) => {
  const [searchParams] = useSearchParams()
  const [showMenu, setShowMenu] = useState(
    searchParams.get('show-menu') !== 'false',
  )

  useEffect(() => {
    if (!showMenu) {
      setSessionItem('show-menu', 'false')
    }
    if (getSessionItem('show-menu') === 'false') {
      setShowMenu(false)
    }
  }, [showMenu])

  return <Context.Provider value={showMenu}>{children}</Context.Provider>
}

export const useShowMenu = () => {
  const showMenu = useContext(Context)

  return showMenu
}
