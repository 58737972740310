import api from 'js/api'
export const getUserProfile = () =>
  Promise.all([
    api('GET', 'userprofile/v3/me', undefined, { cache: 'no-store' }),
    api('GET', 'v2/store/subscription'),
  ]).then(([profile, { response }]) => ({
    ...profile,
    ...response,
  })) as Promise<{
    // userprofile/v3/me
    userid: number
    firstname: string
    lastname: string
    gender: 'female' | 'male'
    country: string
    email: string
    birthdate: string
    registerdate: string
    startdate: string
    profile_image: string
    height: number
    // subscription.response
    start_date: string
    end_date: string
    type: number
    status: 'expired' | 'active' | 'canceled'
    payment_method: 'free' | 'paid'
    store: 'web' | 'ios' | 'android'
    new_style_subscription: boolean
    next_charge_date: null | string
    next_charge_price: null | string
  }>
